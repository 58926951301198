import React, { useState, useEffect } from "react";
import Header from "./template/header";
import Footer from "./template/footer";
import {
  Card,
  Table,
  ButtonGroup,
  ToggleButton,
  Form,
  Button,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../myAxios";
import { firstDay, mySqlDate, ddMMMMyyyy } from "../helper";
import { useNavigate } from "react-router-dom";
import {
  Pen,
  Trash,
  CalendarRange,
  Printer,
  Stopwatch,
  Briefcase,
  CalendarWeek,
} from "react-bootstrap-icons";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const Home = () => {
  const [lembur, setLembur] = useState([]);
  const [total_jam, setTotalJam] = useState(0);
  const [total_menit, setTotalMenit] = useState(0);
  const [startDate, setStartDate] = useState(firstDay());
  const [endDate, setEndDate] = useState(mySqlDate(new Date()));
  const [tanggal_cuti, setTanggalCuti] = useState(mySqlDate(new Date()));
  const [karyawan_cuti, setKaryawanCuti] = useState([]);
  const [cuti, setCuti] = useState([]);
  const [ledger, setLedger] = useState([]);
  const [counter, setCounter] = useState(0);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [cekProfil, setCekProfil] = useState(true);

  const navigate = useNavigate();
  const id_karyawan = localStorage.getItem("id_user");

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/lembur/detail/${id_karyawan}?start_date=${startDate}&end_date=${endDate}`)
        .then((response) => {
          setLembur(response.data.data);
          setTotalJam(response.data.total_jam);
          setTotalMenit(response.data.total_menit);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    loadTable();
  }, [startDate, endDate, counter, id_karyawan]);

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/jurnal_cuti/cuti/${tahun}/${id_karyawan}`)
        .then((response) => {
          setCuti(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }

    async function ledger() {
      const ledger = {
        tahun: tahun,
        id_karyawan: id_karyawan,
      };

      Axios.post(`/jurnal_cuti/ledger`, ledger)
        .then((response) => {
          setLedger(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }

    loadTable();
    ledger();
  }, [tahun, id_karyawan, counter]);

  useEffect(() => {
    async function cek_profil() {
      await Axios.get(`/karyawan/cek_profil/${id_karyawan}`)
        .then((response) => {
          setCekProfil(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getEmployeeOnLeave() {
      await Axios.get(`/jurnal_cuti/cuti/${tanggal_cuti}`)
        .then((response) => {
          setKaryawanCuti(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    cek_profil();
    getEmployeeOnLeave();
  }, [id_karyawan, tanggal_cuti]);

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/lembur/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter + 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  function deleteLeave(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/jurnal_cuti/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter + 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Row>
          <Col md="4">
            <Row className="mt-3">
              <Col xs="6">
                <div className="d-grid gap-2">
                  <Button
                    variant="danger"
                    size="lg"
                    style={{ padding: 15, color: "white" }}
                    onClick={() => navigate(`/apply_leave`)}
                    disabled={!cekProfil}
                    className="btn-block"
                  >
                    <CalendarRange size={32} />
                    <div className="mt-3" style={{ fontSize: 15 }}>
                      Apply Leave
                    </div>
                  </Button>
                </div>
              </Col>
              <Col xs="6">
                <div className="d-grid gap-2">
                  <Button
                    variant="danger"
                    size="lg"
                    style={{ padding: 15, color: "white" }}
                    onClick={() => navigate(`/half_day_leave`)}
                    disabled={!cekProfil}
                    className="btn-block"
                  >
                    <CalendarWeek size={32} />
                    <div className="mt-3" style={{ fontSize: 15 }}>
                      Half Day Leave
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs="6">
                <div className="d-grid gap-2">
                  <Button
                    variant="danger"
                    size="lg"
                    style={{ padding: 15, color: "white" }}
                    onClick={() => navigate(`/travel_log`)}
                    disabled={!cekProfil}
                    className="btn-block"
                  >
                    <Briefcase size={32} />
                    <div className="mt-3" style={{ fontSize: 15 }}>
                      Travel Log
                    </div>
                  </Button>
                </div>
              </Col>
              <Col xs="6">
                <div className="d-grid gap-2">
                  <Button
                    variant="danger"
                    size="lg"
                    style={{ padding: 15, color: "white" }}
                    onClick={() => navigate(`/lembur/create`)}
                    disabled={!cekProfil}
                    className="btn-block"
                  >
                    <Stopwatch size={32} />
                    <div className="mt-3" style={{ fontSize: 15 }}>
                      Overtime
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Card className="mt-3">
                <Card.Header>
                  <Row>
                    <Col md="6" xs="6">
                      <div className="mt-1" style={{ fontWeight: "bold", fontSize: 14 }}>
                        EMPLOYEE ON LEAVE
                      </div>
                    </Col>
                    <Col md="6" xs="6">
                      <Form.Group as={Row}>
                        <Col>
                          <Form.Control
                            size="sm"
                            required
                            type="date"
                            name="tanggal_cuti"
                            defaultValue={tanggal_cuti}
                            onChange={(e) => setTanggalCuti(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Table responsive striped bordered hover size="sm" className="compact">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {karyawan_cuti.length > 0 &&
                        karyawan_cuti.map((dt, index) => (
                          <tr key={index}>
                            <td>{dt.nama_karyawan}</td>
                            <td>
                              {parseInt(dt.status_cuti) === 0 ? (
                                <Badge bg="secondary">Waiting</Badge>
                              ) : (
                                <Badge bg="success">Approved</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
            <div className="d-grid gap-2 mt-3"></div>
          </Col>
          <Col md="8">
            <Card className="mt-3">
              <Card.Header>
                <Row>
                  <Col md="3">
                    <div className="mt-1" style={{ fontWeight: "bold" }}>
                      DATA OVERTIME
                    </div>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} style={{ fontSize: 14 }}>
                        Start Date
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          size="sm"
                          required
                          type="date"
                          name="startDate"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="4">
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} style={{ fontSize: 14 }}>
                        End Date
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          size="sm"
                          required
                          type="date"
                          name="endDate"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover size="sm" className="compact">
                  <thead>
                    <tr>
                      <th>Tanggal</th>
                      <th>Duration</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lembur.length > 0 &&
                      lembur.map((dt, index) => (
                        <tr key={index}>
                          <td>{ddMMMMyyyy(new Date(dt.tanggal))}</td>
                          <td>{dt.jam + ":" + dt.menit}</td>
                          <td style={{ fontSize: 13 }}>{dt.keterangan}</td>
                          <td style={{ textAlign: "center" }}>
                            <ButtonGroup>
                              <ToggleButton
                                type="radio"
                                variant="warning"
                                size="sm"
                                onClick={() => navigate(`/lembur/edit/${dt.id}`)}
                                disabled={parseInt(dt.approved) === 1 ? true : false}
                                title="Edit"
                              >
                                <Pen />
                              </ToggleButton>
                              <ToggleButton
                                type="radio"
                                variant="danger"
                                size="sm"
                                onClick={() => confirmDelete(dt.id)}
                                disabled={parseInt(dt.approved) === 1 ? true : false}
                                title="Delete"
                              >
                                <Trash />
                              </ToggleButton>
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div style={{ float: "right", fontWeight: "bold" }}>
                  Total Duration ({total_jam}:{total_menit})
                </div>
              </Card.Footer>
            </Card>

            <Card className="mt-3">
              <Card.Header>
                <Row>
                  <Col md="3">
                    <div className="mt-1" style={{ fontWeight: "bold" }}>
                      LEAVE REQUEST
                    </div>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} style={{ fontSize: 14 }}>
                        Year
                      </Form.Label>
                      <Col sm={6}>
                        <Form.Control
                          required
                          type="number"
                          name="tahun"
                          size="sm"
                          defaultValue={tahun}
                          onChange={(e) => setTahun(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover size="sm" className="compact">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Leave Type</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Duration</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuti.map((dt, index) => (
                      <tr key={index}>
                        <td>{dt.nama_karyawan}</td>
                        <td>{dt.nama_jenis}</td>
                        <td>{dt.tanggal_awal}</td>
                        <td>{dt.tanggal_akhir}</td>
                        <td>{dt.durasi} Day(s)</td>
                        <td style={{ textAlign: "center" }}>
                          {parseInt(dt.status_cuti) === 0 ? (
                            <Badge bg="secondary">Waiting</Badge>
                          ) : (
                            <Badge bg="success">Approved</Badge>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <ButtonGroup>
                            <ToggleButton
                              type="radio"
                              variant="success"
                              size="sm"
                              onClick={() =>
                                window.open(`/leave_application_print/${dt.id}`, "_blank")
                              }
                              title="Form"
                            >
                              <Printer />
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="danger"
                              size="sm"
                              onClick={() => deleteLeave(dt.id)}
                              disabled={parseInt(dt.status_cuti) === 1 ? true : false}
                              title="Delete"
                            >
                              <Trash />
                            </ToggleButton>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card className="mt-3">
              <Card.Header>
                <Row>
                  <Col md="4">
                    <div className="mt-1" style={{ fontWeight: "bold" }}>
                      LEAVE BALANCE
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover size="sm" className="compact">
                  <thead>
                    <tr>
                      <th>Leave Type</th>
                      <th>Effective Date</th>
                      <th>Last Validity</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledger.map((dt, index) => (
                      <tr key={index}>
                        <td>{dt.jenis_cuti}</td>
                        <td>{dt.tanggal_awal}</td>
                        <td>{dt.tanggal_akhir}</td>
                        <td>{dt.balance}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
